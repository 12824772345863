import * as React from "react"
import Layout from "../components/layout"
import JobPostsFilterSub from "../components/jobPostsFilterSub"
import Seo from "../components/seo"
import JobsNav from "../components/jobsNav"
import JobsNavChefs from "../components/jobsNavChefs"

const Butcher = () => (
  <Layout jobPage={true} splashPage={false} pageTitle="VACANCIES - CHEFS">
    <Seo title="Vacancies chefs" />
    <JobsNav link={"chefs"} />
    <JobsNavChefs link={"butcher"} />
    <JobPostsFilterSub category={2} subcategory={"Chefs - Delis Chef"} />
  </Layout>
)

export default Butcher
